import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EuroIcon from '@mui/icons-material/Euro';
import { Button, ButtonGroup, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetDate, GetTime } from '../../app/AppUtils';
import { fetchPayLaterPerformance } from '../register/registerSlice';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { fetchMolliePayment, fetchTransactionByTransactionId, postPayment, postReservation, resetPaymentPost } from './mollieSlice';
import ContentLoader from '../subcomponents/ContentLoader';

export default function MolliePaymentButton({ buttonMode, performanceId, transactionId = null, subscriptionId = null }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.user);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { payLaterPerformance, payLaterPerformanceStatus } = useSelector((state) => state.register);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { paymentResult, paymentResultStatus } = useSelector((state) => state.mollie);
    const { transaction, transactionStatus, molliePayment } = useSelector((state) => state.mollie);

    // Fetch performance info from DB
    useEffect(() => {
        dispatch(fetchPayLaterPerformance({ performanceId: performanceId }));
    }, [performanceId]);

    // Fetch ideal transaction from DB
    useEffect(() => {
        if (transactionId && buttonMode !== "mollieReturn" && siomSettings?.mollieActive)
            dispatch(fetchTransactionByTransactionId(transactionId));
    }, [transactionId])

    // Fetch Mollie payment from Mollie
    useEffect(() => {
        if (transactionStatus === "success" && (
            transaction?.status?.toLowerCase() === "open" ||
            transaction?.status?.toLowerCase() === "authorized" ||
            transaction?.status?.toLowerCase() === "pending")
        ) {
            dispatch(fetchMolliePayment(transaction.transactieId));
        }
    }, [transactionStatus]);

    // NEW IDEAL TRANSACTION and NEW RESERVASTION function
    const handleNewPaymentClick = () => {
        dispatch(postPayment({
            studentId: user?.studentId,
            performanceId: payLaterPerformance?.performanceId,
            departmentId: siomSettings?.useMultipleDepartments ? activeDepartment[0]?.id : null,
            createdBy: `AP/${user?.number}`,
            amount: payLaterPerformance?.cost,
            description: `${payLaterPerformance?.activity.name} - ${user?.number} - ${payLaterPerformance?.performanceNumber}`,
            redirectUrl: `${window.Config.apiUrl}betaling/`,
            reservationExists: false
        }));
    };

    // NEW IDEAL TRANSACTION and OLD RESERVATION function
    const handleNewPaymentOldReservationClick = () => {
        dispatch(postPayment({
            studentId: user?.studentId,
            performanceId: payLaterPerformance?.performanceId,
            departmentId: siomSettings?.useMultipleDepartments ? activeDepartment[0]?.id : null,
            createdBy: `AP/${user?.number}`,
            amount: payLaterPerformance?.cost,
            description: `${payLaterPerformance?.activity.name} - ${user?.number} - ${payLaterPerformance?.performanceNumber}`,
            redirectUrl: `${window.Config.apiUrl}betaling/`,
            reservationExists: true,
            reservationId: subscriptionId
        }));
    };

    // OPEN payment function
    const handleOpenPaymentClick = () => {
        window.open(molliePayment?.links.checkout.href, "_self")
    };

    // PAY LATER function
    const handlePayLaterClick = () => {
        dispatch(postReservation({
            studentId: user?.studentId,
            performanceId: payLaterPerformance?.performanceId,
            departmentId: siomSettings?.useMultipleDepartments ? activeDepartment[0]?.id : null,
            createdBy: `AP/${user?.number}`,
            amount: payLaterPerformance?.cost,
            description: `${payLaterPerformance?.activity.name} - ${user?.number} - ${payLaterPerformance?.performanceNumber}`,
            redirectUrl: `${window.Config.apiUrl}betaling/`,
            reservationExists: false
        }));

        navigate("/");
    };

    // Open Mollie window if new payment is successfully made
    useEffect(() => {
        if (paymentResultStatus === "success") {
            window.open(paymentResult.links.checkout.href, "_self");
        } else if (paymentResultStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.PAYMENT_CREATION_ERROR}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetPaymentPost());
        };
    }, [paymentResultStatus])

    return (
        <>
            {siomSettings?.mollieActive && (
                buttonMode === "registerSummary" ? (
                    <ButtonGroup variant="outlined">
                        {siomSettings?.mollieEnableDelay &&
                            <Button color="error" startIcon={<AccessTimeIcon />} onClick={handlePayLaterClick}>
                                {resourceFinal[appLanguage].words.LATER} {resourceFinal[appLanguage].words.PAY.toLowerCase()}
                            </Button>}
                        <Button color="success" startIcon={<EuroIcon />} onClick={handleNewPaymentClick}>
                            {resourceFinal[appLanguage].words.PAY}
                        </Button>
                    </ButtonGroup>
                ) : (
                    <ContentLoader
                        textDisabled
                        errorCompact
                        successCondition={payLaterPerformanceStatus === "success"}
                        errorCondition={payLaterPerformanceStatus === "failed"}
                        content={
                            (buttonMode === "schedule" || buttonMode === "mollieReturn") && (
                                <>
                                    {transaction?.status?.toLowerCase() === "open" && (
                                        <Stack spacing={0.5} sx={{ flex: '1 1 auto' }}>
                                            <Button
                                                variant="outlined"
                                                color="success"
                                                sx={{ width: 120, alignSelf: 'center', mt: 1 }}
                                                startIcon={<EuroIcon />}
                                                onClick={handleOpenPaymentClick}
                                            >
                                                {resourceFinal[appLanguage].words.PAY}
                                            </Button>
                                            <Typography variant="body1" textAlign="center">
                                                {resourceFinal[appLanguage].messages.PAYMENT_OPEN} {GetDate(molliePayment?.expiresAt)} {GetTime(molliePayment?.expiresAt)}
                                            </Typography>
                                        </Stack>
                                    )}

                                    {(siomSettings?.mollieEnableDelay && (!transactionId || (transaction?.status?.toLowerCase() !== "paid" && transaction?.status?.toLowerCase() !== "open"))) && (
                                        <Button
                                            variant="outlined"
                                            color="success"
                                            sx={{ width: 120, alignSelf: 'center', mt: 1 }}
                                            startIcon={<EuroIcon />}
                                            onClick={handleNewPaymentOldReservationClick}>
                                            {resourceFinal[appLanguage].words.PAY}
                                        </Button>
                                    )}
                                </>
                            )
                        } />
                )
            )}
        </>
    );
}
