import { Box, Grid, Modal } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GetDate } from '../../app/AppUtils';
import ListItemCard from '../subcomponents/ListItemCard';
import StudentInfoEditCard from './StudentInfoEditCard';

import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import CakeIcon from '@mui/icons-material/Cake';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import Groups2Icon from '@mui/icons-material/Groups2';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Man4Icon from '@mui/icons-material/Man4';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SchoolIcon from '@mui/icons-material/School';
import TrainIcon from '@mui/icons-material/Train';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 400 },
    boxShadow: 24,
};

export default function StudentInfoContentV1({ content, editable }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [open, setOpen] = useState(false);
    const [objectToChange, setObjectToChange] = useState();

    const handleOpen = (object) => {
        setOpen(true);
        setObjectToChange(object);
    };

    const handleClose = () => setOpen(false);

    return (
        <>
            <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<PersonIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.initials ? `${content?.initials} (${content?.firstName}) ${content?.middleName} ${content?.lastName}` : `${content?.firstName} ${content?.middleName} ${content?.lastName}`}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_NAME}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<CakeIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={GetDate(content?.dateOfBirth)}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_BIRTHDATE}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<SchoolIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.number}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_STUDENTNUMBER}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<EmailIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.workEmail}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_EMAIL_SCHOOL}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<GroupsIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.class ? content?.extraInfo?.class : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_CLASS}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<Groups2Icon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.studyYear ? content?.extraInfo?.studyYear : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_YEAR}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<Man4Icon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.mentor ? content?.extraInfo?.mentor : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_MENTOR}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<HistoryEduIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.course ? content?.extraInfo?.course : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_COURSE}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<LocationCityIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.city ? content?.extraInfo?.city : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_CITY}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<AssignmentReturnedIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.pokTurnedIn ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_POK}
                        {...(editable && {
                            action: () => handleOpen({
                                field: 'pok',
                                label: resourceFinal[appLanguage].words.USER_DATA_POK,
                                currentValue: content?.extraInfo?.pokTurnedIn,
                                errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                type: 'bool'
                            }),
                            actionIcon: <EditIcon color="secondary" />,
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<PaymentIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.ibanNumber ? content?.extraInfo?.ibanNumber : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_IBAN}
                        {...(editable && {
                            action: () => handleOpen({
                                field: 'iban',
                                label: resourceFinal[appLanguage].words.USER_DATA_IBAN,
                                currentValue: content?.extraInfo?.ibanNumber,
                                errorMessage: resourceFinal[appLanguage].messages.INVALID_IBAN,
                                type: 'default'
                            }),
                            actionIcon: <EditIcon color="secondary" />,
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<PhoneAndroidIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.privatePhone ? content?.privatePhone : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}
                        {...(editable && {
                            action: () => handleOpen({
                                field: 'mobilePhone',
                                label: resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER,
                                currentValue: content?.privatePhone,
                                errorMessage: resourceFinal[appLanguage].messages.INVALID_PHONE,
                                type: 'default'
                            }),
                            actionIcon: <EditIcon color="secondary" />,
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<PhoneIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.emergencyPhone ? content?.extraInfo?.emergencyPhone : "-"}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER_ICE}
                        {...(editable && {
                            action: () => handleOpen({
                                field: 'anyPhone',
                                label: resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER_ICE,
                                currentValue: content?.extraInfo?.emergencyPhone,
                                errorMessage: resourceFinal[appLanguage].messages.INVALID_PHONE,
                                type: 'default'
                            }),
                            actionIcon: <EditIcon color="secondary" />,
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<DirectionsCarIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.drivingLicence ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_LICENCE}
                        {...(editable && {
                            action: () => handleOpen({
                                field: 'drivingLicence',
                                label: resourceFinal[appLanguage].words.USER_DATA_LICENCE,
                                currentValue: content?.extraInfo?.drivingLicence,
                                errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                type: 'bool'
                            }),
                            actionIcon: <EditIcon color="secondary" />,
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<TrainIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.ovWeek ? resourceFinal[appLanguage].words.STUDENT_DATA_WEEK : content?.extraInfo?.ovWeekend ? resourceFinal[appLanguage].words.STUDENT_DATA_WEEKEND : resourceFinal[appLanguage].words.NO}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_OV}
                        {...(editable && {
                            action: () => handleOpen({
                                field: 'ovSubscription',
                                label: resourceFinal[appLanguage].words.USER_DATA_OV,
                                currentValue: `${content?.extraInfo?.ovWeek ? resourceFinal[appLanguage].words.STUDENT_DATA_WEEK : content?.extraInfo?.ovWeekend ? resourceFinal[appLanguage].words.STUDENT_DATA_WEEKEND : "Geen"}`,
                                errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                type: 'ovSubscription'
                            }),
                            actionIcon: <EditIcon color="secondary" />,
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItemCard
                        avatarIcon={<CoronavirusIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={content?.extraInfo?.allergy}
                        secondaryText={resourceFinal[appLanguage].words.USER_DATA_ALLERGY}
                        {...(editable && {
                            action: () => handleOpen({
                                field: 'allergy',
                                label: resourceFinal[appLanguage].words.USER_DATA_ALLERGY,
                                currentValue: content?.extraInfo?.allergy,
                                errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                type: 'default'
                            }),
                            actionIcon: <EditIcon color="secondary" />,
                        })}
                    />
                </Grid>
            </Grid>

            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <StudentInfoEditCard studentInfo={content} object={objectToChange} />
                </Box>
            </Modal>
        </>
    );
}