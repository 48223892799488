import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreateIcon from '@mui/icons-material/Create';
import EuroIcon from '@mui/icons-material/Euro';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Card, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import * as DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CardHeader, CardLarge, MainIconStyle, SubHeader } from '../../app/AppStyles';
import MolliePaymentButton from '../mollie/MolliePaymentButton';
import BackButton from '../subcomponents/BackButton';
import ContentLoader from '../subcomponents/ContentLoader';
import IconDisplay from '../subcomponents/IconDisplay';
import LocationButton from '../subcomponents/LocationButton';
import PageLayout from '../subcomponents/PageLayout';
import WarningDisplay from '../subcomponents/WarningDisplay';
import RegisterMomentsDisplay from './RegisterMomentsDisplay';
import { fetchPerformance, postSubscription } from './registerSlice';

export default function RegisterSummary() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id, name } = useParams();

    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { performance, performanceStatus } = useSelector((state) => state.register);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [pointDisplay, setPointDisplay] = useState("-");

    useEffect(() => {
        if (siomSettings?.flagProgress) {
            setPointDisplay(performance?.activityMoments?.length);
        } else {
            if (performance?.points !== null && performance?.points !== 0) {
                setPointDisplay(performance?.points);
            } else if (performance?.activity?.points !== null && performance?.activity?.points !== 0) {
                setPointDisplay(performance?.activity?.points);
            };
        };
    }, [siomSettings, performance]);

    useEffect(() => {
        if (performanceStatus === null) {
            dispatch(fetchPerformance({ performanceId: id }));
        };
    }, [dispatch, id, performanceStatus]);

    const locationClick = (location) => window.open(`https://www.google.nl/maps/dir//+${location}`, '_blank');

    const enrollClick = () => {
        if (siomSettings.useMultipleDepartments) {
            if (activeDepartment !== null) {
                dispatch(postSubscription({ studentId: null, performanceId: id, departmentId: activeDepartment[0].id }));
            };
        } else {
            dispatch(postSubscription({ studentId: null, performanceId: id, departmentId: null }));
        };

        navigate("/");
    };

    const ItemFragment = ({ icon, text, label, click, style }) => {
        return (
            <ListItem>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText onClick={click} sx={{ ...style }}
                    primary={text}
                    secondary={label}
                />
            </ListItem>
        );
    };

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.OVERVIEW} ${resourceFinal[appLanguage].words.SUBSCRIPTION.toLowerCase()}`} subtitle={`${decodeURIComponent(name)}`} content={
            <ContentLoader successCondition={performanceStatus === "success"} errorCondition={performanceStatus === "failed"} content={
                <Card sx={{ ...CardLarge, p: 1 }}>
                    <Grid container spacing={1}>
                        {siomSettings.isReservation && <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <WarningDisplay message={resourceFinal[appLanguage].messages.RESERVATION_WARNING} />
                        </Grid>}
                        {performance?.paidActivity && <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <WarningDisplay message={`${resourceFinal[appLanguage].messages.PAID_CLINIC} ${Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(performance?.cost)}!`} />
                        </Grid>}
                        <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                            <Box sx={{ flex: '1 1 auto' }}>
                                <Typography variant="h6" sx={{ ...CardHeader, fontSize: { xs: '1.1rem', md: '1.25rem' } }}><strong>{resourceFinal[appLanguage].words.DATA}</strong></Typography>
                                <List dense>
                                    <ItemFragment
                                        icon={<IconDisplay component={performance?.activity.iconName} style={{ p: 1, color: 'primary.main' }} />}
                                        text={<>{performance?.activity.name} {performance?.performanceName ? `- ${performance?.performanceName}` : ""}</>}
                                        label={resourceFinal[appLanguage].words.ACTIVITY}
                                    />
                                    <ItemFragment
                                        icon={appSettings?.appVersion === 1 ? <BarChartIcon sx={{ p: 1 }} color="primary" /> : <AccessTimeIcon sx={{ p: 1 }} color="primary" />}
                                        text={pointDisplay}
                                        label={`${appSettings?.appVersion === 1 ? (pointDisplay === 1 ? resourceFinal[appLanguage].words.POINT : resourceFinal[appLanguage].words.POINTS) : (pointDisplay === 1 ? resourceFinal[appLanguage].words.HOUR : resourceFinal[appLanguage].words.HOURS)}`}
                                    />
                                    {performance?.cost > 0 && <ItemFragment
                                        icon={<EuroIcon sx={{ ...MainIconStyle }} />}
                                        text={Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(performance?.cost)}
                                        label={resourceFinal[appLanguage].words.COST}
                                    />}
                                    <ItemFragment
                                        icon={<CalendarMonthIcon sx={{ ...MainIconStyle }} />}
                                        text={performance?.periode.name}
                                        label={resourceFinal[appLanguage].words.PERIOD}
                                    />
                                    {performance?.activity.contactPerson && <ItemFragment
                                        icon={<PersonIcon sx={{ ...MainIconStyle }} />}
                                        text={performance?.activity.contactPerson}
                                        label={resourceFinal[appLanguage].words.CONTACT}
                                    />}
                                    <ItemFragment
                                        icon={<GroupsIcon sx={{ ...MainIconStyle }} />}
                                        text={performance?.occupation}
                                        label={resourceFinal[appLanguage].words.OCCUPATION}
                                    />
                                    <ItemFragment
                                        icon={<LocationButton locationString={`${performance?.location.street}+${performance?.location.streetNumber}+${performance?.location.city}`} />}
                                        label={resourceFinal[appLanguage].words.LOCATION}
                                        text={<>{performance?.location.name} {performance?.location.street ?
                                            "(" + performance?.location.street + (performance?.location.streetNumber ? " " + performance?.location.streetNumber : "") +
                                            (performance?.location.city ? ", " + performance?.location.city + ")" : ")") :
                                            performance?.location.city ? `(${performance?.location.city})` : ""}</>}
                                        style={{ cursor: 'pointer' }}
                                        click={() => locationClick(`${performance?.location.street}+${performance?.location.streetNumber}+${performance?.location.city}`)}
                                    />
                                </List>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                                <Typography variant="h6" sx={{ ...CardHeader, fontSize: { xs: '1.1rem', md: '1.25rem' } }}><strong>{resourceFinal[appLanguage].words.MOMENTS} ({performance?.activityMoments.length})</strong></Typography>
                                <RegisterMomentsDisplay moments={performance?.activityMoments} amount={5} pagination />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', alignItems: 'center', p: 1 }}>
                        {performance?.remark && (
                            <Box>
                                <Typography variant="h6" sx={{ ...SubHeader }}>{resourceFinal[appLanguage].words.REMARK}</Typography>
                                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(performance?.remark.replace(/\\n/g, '<br />')) }}></Typography>
                            </Box>
                        )}
                        {(performance?.paidActivity && siomSettings?.mollieActive) ? (
                            <Stack spacing={1} alignItems='center' sx={{ mt: 'auto', p: 0.5 }}>
                                <Typography variant="body1" textAlign="center">{resourceFinal[appLanguage].messages.PAYMENT_EXPLANATION}</Typography>
                                <MolliePaymentButton buttonMode="registerSummary" performanceId={performance?.performanceId} />
                            </Stack>
                        ) : (
                            <Box sx={{ mt: 'auto' }}>
                                <Button variant="outlined" color="secondary" startIcon={<CreateIcon />} onClick={enrollClick}>{resourceFinal[appLanguage].messages.SUBSCRIPTION_CONFIRMATION}</Button>
                            </Box>
                        )}
                    </Box>
                </Card>
            } />
        } bottomBar contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } />
    );
}
